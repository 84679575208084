body {
	margin: 0px;

}




.tankwash .mainBar {
	width: 100%;
	background-color: #d1ddfa;
	border-radius: 6px;
	height: 60px;
	display: flex;
	position: relative;
}



 


.tankwash_top_hrs .am_section {
    width: 5%;
    color: #2d2d2d;
    text-align: right;
    padding-right: 10px;
}
 

.tankwash_top_hrs .mainBar_top_hrs {
	width: 100%;
	background-color: #4267B2;
	border-radius: 6px;
	height: 60px;
	display: flex;
	align-items: end;
}







 .tankwash_day_time_inner {
	/* width: 4.18%; */
	border-right: 1px solid #fff;
	color: #fff;
	text-align: center;
	height: 2.5rem;
	display: flex;
	justify-content: center;
	align-items: end;
	font-size: 11px;
	
  }

  .mainBar_top_hrs .tankwash_day_time_inner:last-child {
	width: 4.18%;
	border-right: 1px solid transparent;
	color: #fff;
	text-align: center;
	height: 2.5rem;
	display: flex;
	justify-content: center;
	align-items: end;
	font-size: 11px;
  }

  .tankwash_top_hrs {
	display: flex;
	align-items: center;
  }





.mainBar_top {
	position: absolute;
    top: 0px;
	background-color: rgb(124, 162, 233);
	/* color: #fff; */
	color: red;
	border-radius: 4px;
	height: 100%;
	padding-left: 5px;
}
.mainBar_top_in_time {
	position: absolute;
    top: 0px;
	background-color: #add8e6;
	/* color: #fff; */
	color: darkgreen;
	border-radius: 4px;
	height: 100%;
	padding-left: 5px;
}
.mainBar_top1 {
	position: absolute;
    top: 0px;
	background-color:rgb(109 115 127);
	color: red;
	border-radius: 4px;
	height: 100%;
	padding-left: 5px;
}
.mainBar_top1_in_time {
	position: absolute;
    top: 0px;
	background-color:rgb(109 115 127);
	color: darkgreen;
	border-radius: 4px;
	height: 100%;
	padding-left: 5px;
}
.mainBar_top2 {
	position: absolute;
    top: 0px;
	background-color: rgb(127, 234, 152);
	/* color: #fff; */
	color: white;
	border-radius: 4px;
	height: 100%;
	padding-left: 5px;
}



.scheduled_order1 {
	position: absolute;
    top: 0px;
	background-color: rgb(231, 235, 218);
	/* color: #fff; */
	color: green;
	border-radius: 4px;
	height: 100%;
	padding-left: 5px;
	font-weight: bold;
	text-overflow: ellipsis;
	/* white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis; */

}
.scheduled_order2 {
	position: absolute;
    top: 0px;
	background-color: rgb(231, 235, 218);
	/* color: #fff; */
	color: red;
	border-radius: 4px;
	height: 100%;
	padding-left: 5px;
	font-weight: bold;
	text-overflow: ellipsis;
	/* white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis; */

}

.scheduled_order3 {
	position: absolute;
    top: 0px;
	background-color: rgb(211, 211, 211);
	/* color: #fff; */
	color: green;
	border-radius: 4px;
	height: 100%;
	padding-left: 5px;
	font-weight: bold;
	text-overflow: ellipsis;
	/* white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis; */

}
.scheduled_order4 {
	position: absolute;
    top: 0px;
	background-color: rgb(211, 211, 211);
	/* color: #fff; */
	color: red;
	border-radius: 4px;
	height: 100%;
	padding-left: 5px;
	font-weight: bold;
	text-overflow: ellipsis;
	/* white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis; */

}

.scheduled_order5 {
	position: absolute;
    top: 0px;
	background-color: rgb(172, 229, 238);
	/* color: #fff; */
	color: green;
	border-radius: 4px;
	height: 100%;
	padding-left: 5px;
	font-weight: bold;
	text-overflow: ellipsis;
	/* white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis; */

}
.scheduled_order6 {
	position: absolute;
    top: 0px;
	background-color: rgb(172, 229, 238);
	/* color: #fff; */
	color: red;
	border-radius: 4px;
	height: 100%;
	padding-left: 5px;
	font-weight: bold;
	text-overflow: ellipsis;
	/* white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis; */

}

.scheduled_order7 {
	position: absolute;
    top: 0px;
	background-color: rgb(172, 229, 238);
	color: black;
	border-radius: 4px;
	height: 100%;
	padding-left: 5px;
	text-overflow: ellipsis;
	/* white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis; */

}


.tankwash_topdate {
	text-align: center;
}
.blank_space {
	width: 100px;	
}

.date_container {
	width: 22%;
}

.date_container__first {
	display: flex;
 	justify-content: center;
  	align-items: center
}

/* .date_picker .MuiOutlinedInput-adornedEnd {
    padding-right: 0px;
} */

.tankwash_topdate1 {	
    display: flex;
    justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
}

.tankwash_topdate_text {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 24px;
	color: #2d2d2d;
	font-family: "Manrope-Bold";

}

.tankwash_topdate_text .MuiPickersUtilsProvider {
    margin-bottom: 0px;
}

.tankwash_topdate_text .MuiIconButton-root{
    flex: 0 0 auto;
    color: rgba(0, 0, 0, 0.54);
    padding: 0px;
    overflow: visible;
    font-size: 1.5rem;
    text-align: center;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
}

.meterial_autocomplete .MuiOutlinedInput-input {
    padding: 9.5px 14px;
}

.MuiOutlinedInput-inputMarginDense {
    padding: 9.5px 14px;
}

.tankwash_topdate_gmt {
	font-size: 16px;
    color: #000;
    padding-bottom: 10px;

}

.tankwash_board_left_image {
	width: 5%;
	text-align: center;
}

.tankwash_board_right_image {
	width: 3%;
}
.tankwash_main_inner_container {
	width: 4.16%;
}

.hrs_wrapper {
	background: #4267B2;
	position: relative;
	padding: 10px 0px;
	
}
.tankwash_dragable_container {
	background: #d1ddfa;
	margin-top:0px;
	display: flex;
	position: relative;
	
}
.tankwash_main_container {
	display: flex;
	width: 100%;
	
}



.tankwash_top_header_wrapper {
	display: flex;
	align-items: center;
}
.meterial_autocomplete_tankwash .MuiAutocomplete-popupIndicator {
    padding: 2px;
    margin-right: -2px;
    color: white;
}
.meterial_autocomplete_tankwash .MuiOutlinedInput-notchedOutline {
    top: 0px;
    border: 1px solid white;
}

.MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23);
}
.tankwash_dragable_container_label {
	margin-top: 10px;
}
.meterial_autocomplete_tankwash .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    /* border-color: rgba(0, 0, 0, 0.87); */
    border: 1px solid white;
}

.meterial_autocomplete_tankwash .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
    padding: 0px 4px;
    color: white;
}

.meterial_autocomplete_tankwash .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #ffffff;
    border-width: 2px;
}

.meterial_autocomplete_tankwash .MuiIconButton-root:hover {
    background-color: rgba(0, 0, 0, 0.04);
    color: white;
}

.meterial_autocomplete_tankwash .MuiIconButton-root {
    background-color: rgba(0, 0, 0, 0.04);
    color: white;
}

.hrs_wrapper_time_text {
	color: white;
	text-align: center;
	font-size: 11px;
	position: absolute;
	top: 5px;
    width: 100%;
}

.vl_plannertankwash {
    border-left: 1px solid white;
    height: 20px;
    
}

.close_planning_outer_tankwash {
    position: absolute;
    right: -6px;
    top: -10px;
}

.close_planning_tankwash { 
	margin-top: 0em; 
  }
  .close_planning_tankwash:before {
	content: "✖";
	/* border: 1px solid gray; */
	background-color: #eee;
	box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.57);
	padding: 0px 3px;
	cursor: pointer;
	/* width: 28px; */
	/* height: 10px; */
	border-radius: 40px;
	color: black;
	font-size: 10px;
  }


.tankwash_dragable_inner_dark {
    width: calc(100% / 96);    
    height: 60px;
    display: flex;
    flex-direction: row;
    background: #545b71;
	
}
.tankwash_dragable_inner_dark:nth-child(even) {
    /* width: calc(100% / 48);     */
    height: 60px;
    display: flex;
    flex-direction: row;
    background: #545b71;
	border-right: 1px solid white !important;
}

.tankwash_dragable_inner {
	width: calc(100% / 96);
	border-right: 1px solid #fff;
	height: 60px;
	display: flex;
	flex-direction: row;
}

.tankwash_dragable_inner:nth-child(odd) {
	border-right: 1px solid transparent
}