body {
    margin: 0px;
}
.tankwash .mainBar {
    width: 100%;
    background-color: #d1ddfa;
    border-radius: 6px;
    height: 60px;
    display: flex;
    position: relative;
}
.tankwash_top_hrs .am_section {
    width: 5%;
    color: #2d2d2d;
    text-align: right;
    padding-right: 10px;
}
.tankwash_top_hrs .mainBar_top_hrs {
    width: 100%;
    background-color: #4267B2;
    border-radius: 6px;
    height: 60px;
    display: flex;
    align-items: end;
}
.planner_day_time_inner {
    /* width: 4.18%; */
    border-right: 1px solid #fff;
    color: #fff;
    text-align: center;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: end;
    font-size: 11px;
}
.mainBar_top_hrs .tankwash_day_time_inner:last-child {
    width: 4.18%;
    border-right: 1px solid transparent;
    color: #fff;
    text-align: center;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: end;
    font-size: 11px;
}
.tankwash_top_hrs {
    display: flex;
    align-items: center;
}
.mainBar_top {
    position: absolute;
    top: 0px;
    background-color: rgb(33, 79, 165);
    color: #fff;
    border-radius: 4px;
    height: 100%;
    padding-left: 5px;
}
.mainBar_top1 {
    position: absolute;
    top: 194px;
    height: 30px;
    background-color: yellow;
    
    width: 30%;
    border-radius: 4px;
    left: 20%;
}
.mainBar_top2 {
    position: absolute;
    top: 194px;
    height: 30px;
    background-color: green;
    width: 30%;
    border-radius: 4px;
    left: 40%;
}

.button_text {
  /* display: flex; */
  /* justify-content: center; */
  align-items: center;
    font-size: 10px;
    color: #2d2d2d;
    font-family: "Manrope-Bold";
    margin-right: auto;
}
.tankwash_topdate_gmt {
    font-size: 16px;
    color: #000;
    padding-bottom: 10px;
}
.tankwash_board_left_image {
    width: 5%;
    text-align: center;
}
.tankwash_board_right_image {
    width: 3%;
}
.planner_main_inner_container {
    width: 4.16%;
}
.hrs_wrapper {
    background: #4267B2;
    position: relative;
    padding: 10px 0px;
}
.planner_dragable_container {
    background: #d1ddfa;
    /* margin-top: 40px; */
    display: flex;
    position: relative;
    width: 100%;
    z-index: 0;
}
.planner_tick_container {
    display: flex;
    width: 100%;
    height: 100%;
    
}
.planner_main_container {
    display: flex;
    width: 100%;
}
.planner_dragable_inner {
    width: calc(100% / 48);
    border-right: 1px solid #fff;
    height: 60px;
    display: flex;
    flex-direction: row;
    z-index: 2;
}
.planner_dragable_inner:nth-child(odd) {
    border-right: 1px solid transparent
}
.tankwash_top_header_wrapper {
    display: flex;
    align-items: center;
}
.meterial_autocomplete1 .MuiAutocomplete-popupIndicator {
    padding: 2px;
    margin-right: -2px;
    color: white;
}
.meterial_autocomplete1.meterial_autocomplete .MuiOutlinedInput-notchedOutline {
    top: 0px;
    border: 1px solid white;
}
.meterial_autocomplete1 .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    /* border-color: rgba(0, 0, 0, 0.87); */
    border: 1px solid white;
}
.meterial_autocomplete1 .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
    padding: 0px 4px;
    color: white;
}
.meterial_autocomplete1 .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #ffffff;
    border-width: 2px;
}
.meterial_autocomplete1 .MuiIconButton-root:hover {
    background-color: rgba(0, 0, 0, 0.04);
    color: white;
}
.meterial_autocomplete1 .MuiIconButton-root {
    background-color: rgba(0, 0, 0, 0.04);
    color: white;
}
.hrs_wrapper_time_text {
    color: white;
    text-align: center;
    font-size: 11px;
    position: absolute;
    top: 5px;
    width: 100%;
}
.vl_plannertankwash {
    border-left: 1px solid white;
    height: 20px;
}
.tankwash_dragable_inner_dark {
    /* width: calc(100% / 48); */
    height: 60px;
    display: flex;
    flex-direction: row;
    background: #545b71;
}
.planner_top .card-body {
  flex: 1 1 auto;
  padding: .2rem .2rem;
}
.planner_main_container_outer {
    display: flex;
    align-items: center;
    margin-top: 10px;
    height: 55px;
}
 .planner_am_section {
    width: 110px;
    word-break: break-all;
    text-align: center;
}
 .planner_pm_section {
  position: relative;
    width: 50px;
    word-break: break-all;
    text-align: center;
}
.modal_dialog{
  position: absolute;
  left: 200px;
}
.main_planner_wrapper,
.main_planner_wrapper_refresh {
    padding: 0px 0px 40px 0px;
}
.w-5 {
    width: 5%;
  }
  .w-10 {
    width: 10%;
  }
  .planner_main_container_outer_image_container_icon_text {
    font-size: 10px;
    margin-bottom: 0px;
  }
  .w-15 {
    width: 15%;
  }
  .w-22 {
    width: 22%;
  }
  .w-30 {
    width: 30%;
  }
  .w-8 {
    width: 8%;
  }
  .w-80 {
    width: 80%;
  }
  .w-79 {
    width: 79%;
  }
  .ml_19 {
    margin-left: 19%;
  }
  .ml_9 {
    margin-left: 9%;
  }
  .mt_5 {
    margin-top: 5px;
  }
  .planner_dragable_main_block {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 2;
    pointer-events: none;
  }
  .planner_dragable_inner_main_block {
    position: absolute;
    height: 100%;
    width: 100%;
    pointer-events: none;
    z-index: 2;
  }
  .planner_shift {
    pointer-events:all;
    width: calc(100% / 48);
    background-color: #ffffff;
    opacity: 0.8;
    background-size: 10px 10px;
    background-image: repeating-linear-gradient(-45deg, #444cf730 0, #444cf7 2px, #ffffff 0, #ffffff 50%);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    align-items: center;
    font-size: 13px;
    font-weight: bold;
    color: #080808;
    border: 1px solid #444cf7;
    text-align: center;
    height: 100%;
    position: absolute;
  }
  
  .planner_shift_color {
    pointer-events:all;
    width: calc(100% / 48);
    background-color: rgb(244, 247, 66); 
    opacity: 0.8;
    background-size: 10px 10px;
    background-image: repeating-linear-gradient(-45deg, rgb(244, 247, 66) 0, rgb(244, 247, 66) 2px, #ffffff 0, #ffffff 50%);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.829);
    /* border: 1px  solid rgb(244, 247, 66); */
    text-align: center;
    height: 100%;
    position: absolute;
  }
  .planner_draggable_main_block_row_block {
    box-shadow: 0px 0px 2px #000;
      text-align: center;
      font-size: 12px;
      display: flex;
      align-items: center;
      position: relative;
      height: 100%;
      /* padding: 0px 20px; */
      border-radius: 4px;
      background: white;
      justify-content: center;
  }
  .planner_draggable_main_block_row_block_cross{
    /* box-shadow: 0px 0px 2px #000; */
    text-align: center;
    font-size: 12px;
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;
    /* padding: 0px 20px; */
    border-radius: 4px;
    /* background: white; */
    justify-content: center;
    margin-top: 15px;
    margin-left: -4px;
  }
  .planner_draggable_main_block_top_row_block {
    border: 1px solid #8f8f8f;
    pointer-events: all;
  }
  .planner_draggable_main_block_top_row_block_cross{
    pointer-events: all;
  }
  .planner_draggable_main_block_middle_row_block {
    /* border: 1px solid #8f8f8f; */
    /* pointer-events: all; */
  }
  .planner_draggable_main_block_bottom_row_block {
    background: #4267ae;
    color: #fff;
    /* border: 1px solid #444444; */
    pointer-events: all;
    overflow: hidden;
  }
  .planner_draggable_main_block_row {
    position: relative;
    width: 100%;
    height: 33%;
    display: flex;
  }
  .planner_draggable_main_block_middle_row {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .planner_main_container_outer_image_container {
    /* width: 70px; */
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 2px;

  }
  .planner_hos_log1 {
    width: calc(100% / 48);
    display: flex;
    flex-direction: row;
    /* background: #545b71; */
    height: 100%;
    position: absolute;
    z-index: 2;
  }
  .planner_hos_log2 {
    width: calc(100% / 48);
    display: flex;
    flex-direction: row;
    /* background: #545b71; */
    height: 100%;
    position: absolute;
    z-index: 1;
  }

  .text-bold{
    font-weight: bold;
  }

  .planner_main_container_outer_image_container_right_text_wrapper {
    display: flex;
    align-items: center;
    width: 110px;
  }
  .planner_main_container_outer_image_container_right_text p {
    margin-bottom: 0px;
    text-align: right;
    font-size: 11px;
  }
  .mb_0 {
    margin-bottom: 0px;
  }

  .text-format {
    font-size: 1rem;
    color: #2d2d2d;
    font-family: "Manrope-Bold";
    margin-right: auto;
    margin-bottom: 1rem;
  }

  .planning_board_topdate {
    /* text-align: center; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding:16px 48px 0px 100px;
    height: 95px;

}
.planningboard_driver_header{
  width: 100%;
  display: flex;  
  justify-content: space-between;
  align-items: start;
  padding-top: 16px;
}
.planning_board_topdate_inner {
  width: 22%;
}

.planning_board_button_container {
  /* width: 30%; */
  display: flex;
  gap: 10px;
}

.planning_board_refresh_button {
  padding-left: 0px;
}
 
.planning_board_button_container .MuiToggleButtonGroup-root { 
  display: flex;
  justify-content: end; 
  border-radius: 4px; 
}

.planning_board_button_container .MuiToggleButton-root.Mui-selected {
  color: rgb(255 255 255);
  background-color: rgb(66 103 178);
}

.pplanning_board_button_container .MuiToggleButton-root.Mui-selected:hover {
  color: rgb(255 255 255);
  background-color: rgb(66 103 178);
}

.close_planning_outer .close_planning:before {
  content: "✖";
  /* border: 1px solid gray; */
  background-color: #fffcfc;
  padding: 0px 3px;
  cursor: pointer;
  /* width: 28px; */
  /* height: 10px; */
  border-radius: 40px;
  color: #020202;
  font-size: 10px;
  /* position: relative; */
}
.close_planning_outer .close_planning {
  margin-top: 0em;
  position: relative;
}
.pd_rl{
  padding: 7px 20px;
}

.planner_modal .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding: 7px !important;
}

.margin-top-bottom{
  margin: 1rem 0 !important;
}

.planner_modal_change .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
  padding: 0.5px 4px !important;
}

.plannerText {
  padding-left: 95px;
  padding-top: 20px;
}
/* #ytd-url {
  display: block;
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 10px 14px;
  margin: 20px;
  color: #fff;
  font-size: 14px;
  text-decoration: none;
  background-color: #143240;
  border-radius: 4px;
  box-shadow: 0 10px 20px -5px rgba(20, 50, 64, 0.76);
}

.close_icon_section {
  position: absolute;
  right: -5px;
  top: -6px;
  z-index: 99;
}
.close {
  
  width: 16px;
  height: 16px;
  
}
.close:hover {
  opacity: 1;
}
.close:before, .close:after {
  position: absolute;
    left: 7px;
    content: ' ';
    height: 11px;
    width: 2px;
    background-color: #fff;
    top: 2px;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
} */