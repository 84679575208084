.editModalContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 10px 0px;
}

/* .editModalContainer_item {
    display: flex;
    flex-direction: column;
    justify-content: center;   
} */

.editModalContainer_items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px; 
}


.editModalContainer_item_label {
    color: #1a1919;
    font-weight: 800;
}

.driverPayEdit_modal_width {
    width: 45%;
    max-width: none !important;
}

.checkbox_span {
    font-size: 14px;
}

.span_dark {
    color: #343a40;
    font-weight: 600;
    font-family: "IBM Plex Sans", sans-serif;
    font-size: .9rem;
    margin-right: 5px;
}

.editModalCheckItems {
    display: flex;
    /* gap: 42px; */
    align-items: center;
    /* flex: auto; */
}

.div_width {
    width: 90px;
}

.div_width--1 {
    width: 90px;
    margin-right: 15px;
}
.autocomplete_width {
    width: 214px;
}

.editModalCheckboxItems {
    display: flex;
    gap: 10px;
    align-items: center, ;
    flex-direction: row, ;
    flex: 100px 1 1;
}
.meterial_autocomplete_driver .MuiFormControl-root {
    width: 100%;

}
.meterial_autocomplete_driver {
    margin: 10px 0px;
    width: 100%;
}
.meterial_autocomplete_driver .MuiFormControl-marginNormal {
    margin-top: 0px;
}
.meterial_autocomplete_driver .MuiOutlinedInput-notchedOutline {

    top: 0px;

}
.meterial_autocomplete_driver .MuiOutlinedInput-input {
    padding: 9.5px 14px;
}
.meterial_autocomplete_driver .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
    padding: 0px 4px;
}
