.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.btn_table {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 7px 20px 7px 20px;
  font-size: 16px;
  border-radius: 4px;
  
}

.btn-table {
  color: #707070;
  background-color:rgba(24,133,230,0.1);
  border: 1px solid #1885E6;
}

.btn-table:hover {
  color: #707070;
  background-color:rgba(24,133,230,0.1);
  border: 1px solid #1885E6;
}

.btn-table:focus {
  outline: none;
}

.tooltip_row_block {
  overflow: hidden; white-space: nowrap;
  height: 100%;
  width: fit-content;
  margin: 0px auto;
  cursor:default ;
  /* pointer-events: all; */
}
.svg_icon_size_driver {
  width: 48px;
  padding: 10px;
}
.w-100 {
  width: 100%;
}
.settlement_top{
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.driver_tooltip_main_container {
  display: flex;
  justify-content: space-between;
  padding: 0px 8px 20px 8px;
}

.driver_tooltip_main_container_column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;

}

.driver_tooltip_main_container_row {
  display: flex;
  justify-content: flex-start;
  gap: 10px
}

.driver_tooltip_main_container_row_title {
  color: white;
  font-family: "Manrope-Bold";
  font-size: 15px;
}

.driver_tooltip_main_container_row_value {
  color: white;
    font-size: 15px;
    font-family: "Manrope-Regular";
}

.underline {
  cursor: pointer;
  margin-right: 8px;
}

.padding {
  padding-left: 5px;
}

.pointerevent {
  z-index: 25 !important;
} 