    .drivercard{
        max-height: 800px;
        height: max-content;
    }
    .my-modal {
        width: 100vw;   
        max-width: 90vw;
    }

    .kendo_container{
        min-height: auto;
        padding: 0px;
        padding-bottom: 35px;
    }

    .settlement_modal_header {
        background-color: #4267B2;
        border-radius:4px;
       padding: 10px 20px;
       margin: 10px 0px;
    }

    .settlement_modal_header_inner {
        display: flex;
    }

    .settlement_modal_header_left_text {
    color: #fff;
    font-size: 16px;
    font-family: "Manrope-Bold";
    
   
    }
    .settlement_modal_header_left_text_dres{
        color: #ffffffa1;
        font-size: 16px;
        font-family: "Manrope-Regular";
        padding-left: 4px;
    }
    .settlement_modal_header_dres {
        display: flex;
        align-items: center;
        padding: 6px 0px;
    }
    .settlement_modal .MuiBox-root {
        padding: 12px;
        border: none;
    }


    .settlement_modal .MuiBox-root:focus-visible {
       outline: none;
    }
    .audit_log_modal_height {
        max-height: 800px;
        height: auto !important;
    }

    .audit_log_normal_table thead {
        position: relative;
    display: block;
    width: 100%;
    }

    .audit_log_normal_table thead tr {
        width: 100%;
        display: flex;
    
    }
    .audit_log_normal_table thead tr {
        border-bottom-width: 0px;
    }
    
    .audit_log_normal_table thead tr th {
        width: 50%;
    }
    .audit_log_normal_table thead tr th {
        background-color: #1885E6;
        color: #fff;
        border: 1px solid #74b2ea !important;
    }

    .audit_log_normal_table tbody {
        display: block;
        max-height: 300px;
        overflow: auto;
    }

    .audit_log_normal_table tbody tr {
        display: table;
    width: 100%;
    table-layout: fixed;
    }
    .audit_log_normal_table tbody tr td {
        border: 1px solid #ccc;
        color: #2d2d2d;
    }

    .audit_close_sec {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .dxxxf {
        display: flex;
        align-items: center;
        border: 3px solid red;
    }

    .audit_log_modal .MuiModal-backdrop {
        background-color: rgba(0, 0, 0, 0.01);
    }
    .df-print{
        display: flex;
        justify-content: right;
        margin: 8px 0px 12px;
    }
   
